import Swiper from 'swiper';
export function index(controller , scene){
    let indexswipe = document.getElementById('indexswipe');
    if(indexswipe){
        new Swiper('#indexswipe', {
            spaceBetween: 0,
            effect: 'fade',
            speed: 2000,
            pagination: {
                el: '.swiper-pagination',
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
        }); 
    }

}