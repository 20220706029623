export function logout(){
	const l = {
		data: function(){
			return {
				toggle: false,
			}
		},
		template: 
		`
			<div class="login">
				<dl>
					<dt>下記のボタンをクリックすることでログアウトを行えます。</dt>
					<dd class="btnarea"><a @click="toggle = true">ログアウト</a></dd>
				</dl>
				<transition name="fade">
					<div class="overlay" v-if="toggle">
						<div class="overlay__inner">
							<i class="overlay__inner__close" @click="toggle = false"><i class="fas fa-times"></i>閉じる</i>
							<form id="loginform" name="loginform" method="post" action="/include/program/session.php">
								<dl class="overlay__inner__item">
									<dt>ログアウトを行うことで会員限定情報は閲覧出来なくなります。</dt>
									<dd>
										<input type="hidden" name="logout" value="true">
										<input type="submit" class="send" value="ログアウトを続行">
									</dd>
								</dl>
							</form>
						</div>
					</div>
				</transition>
			</div>
		`,
	}
	return l;
}