export function login(){
	const l = {
		data: function(){
			return {
				toggle: false,
				message: 'パスワードが間違っています',
				error: false,
				input: {
					pass: ''
				}
			}
		},
		template: 
		`
			<div class="login">
				<dl>
					<dt>既に会員の方は最新情報をご覧頂けます</dt>
					<dd class="btnarea"><a @click="toggle = true">パスワード入力画面へ</a></dd>
					<dd>パスワードをお忘れの方は事務局までご連絡ください。</dd>
				</dl>
				<transition name="fade">
					<div class="overlay" v-if="toggle">
						<div class="overlay__inner">
							<i class="overlay__inner__close" @click="toggle = false"><i class="fas fa-times"></i>閉じる</i>
							<form id="loginform" name="loginform" method="post" action="/include/program/session.php">
								<dl class="overlay__inner__item">
									<dt>パスワードを入力してください</dt>
									<dd><input type="password" name="pass" v-model="input.pass"></dd>
									<dd><input type="submit" class="send" value="パスワードを送信" onclick="return false" @click="send()"></dd>
								</dl>
							</form>
							<p v-if="error" class="overlay__inner__alert">{{ message }}</p>
						</div>
					</div>
				</transition>
			</div>
		`,
		methods: {
			send: function(){
				const post_url = '/include/program/login.php';
				this.error = false;
				axios.post(post_url , this.input)
					.then(response => {
						if(response.data == 'success'){
							setTimeout(function(){
								document.loginform.submit();
							},100);
						}else{
							this.error = true;
							this.input.pass = '';
						}
					}).catch(error => {
						this.error = true;
						this.input.pass = '';
					});
			}
		}
	}
	return l;
}