import {login} from './component/login';
import {logout} from './component/logout';

export function vues(){
	const v = new Vue({
		el: '#site-header',
		data: {
			header: {
				toggle: false,
			}
		}
	});
	
	const m = new Vue({
		el: '#v-member',
		components: {
			'login': login(),
			'logout': logout(),
		}
	});
}